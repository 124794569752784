<template>
    <div class="music-classifier" v-show="displayMainPage">
        <div class="music-type" @click="changeMusicType">切换方式</div>
        <div class="music-type" v-for="type in musictype" :key="type" @click="changeList(type)">{{ type }}</div>
    </div>
    <div class="music-introduction" v-show="displayMainPage">
            <div class="music-name">音乐</div>
            <div class="music-album-name">专辑</div>
            <div class="music-time">时长</div>
    </div>
    <div class="app" >
        <video class="underlying-video" ref="VideoLoad" muted :style="{'opacity': videoOpacity}" :key="currentDisc._id" v-show="true">
            <source :src="currentDisc.video" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        <MusicList v-for="music in filteredType" v-show="displayMainPage" :style="{'opacity':1 - videoOpacity, 'border-left': music._id === currentDisc._id ? '5px solid rgb(50, 168, 160)' : '','color': windowWidth < 520 ? (music._id === currentDisc._id ? 'rgb(50, 168, 160)' : 'rgb(250, 244, 207)') : (music._id === currentDisc._id ? 'rgb(50, 168, 160)' : 'black')}" :album="music.album" :musicDir="music.src" :disc="music.disc" :band="music.band" :cover="music.cover" :key="music._id" :lyricid="music.lyricid" :pinned="music.pinned? true:false" @click="changeMusic(music._id)" @changepin="handlePinChanged(music._id)"></MusicList>
    </div>
    <div class="music-play-starter">
        <MusicPlayBox :key="currentDisc._id" :_id="currentDisc._id" :disc="currentDisc.disc" :musicDir="currentDisc.src" :cover="currentDisc.cover" :band="currentDisc.band" :lyricid="currentDisc.lyricid" :video="currentDisc.video" :videodelayed="currentDisc.videodelayed" :danmu="currentDisc.danmu" ref="musicStart" @changeSong="handleSongChanged" @VideoController="VideoPlay" ></MusicPlayBox>
    </div>
</template>

<script>

import MusicList from "./musiclist.vue"
import MusicPlayBox from "./musicplay.vue"
import axios from "axios"

export default {
    name: "MusicManager",
    components: {
        MusicList,
        MusicPlayBox
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            musicdata: [],
            listclassifier: "classifier",
            currentType: "Mandarin",
            videoOpacity: 0,
            displayMainPage: true,
            currentDisc: {disc: "等待播放",
            src: "",
            band: "未知",
            cover: "https://th.bing.com/th/id/R.aa3b75d1230a52b692412278657ae711?rik=NNZ6yc9JVBfT7A&riu=http%3a%2f%2fpic.616pic.com%2fys_bnew_img%2f00%2f07%2f02%2fJcQoms2o3U.jpg&ehk=BPTn78rg%2fzTLiu%2f3HT9tbuAf%2b6HHJb6dwJdzgWRFC1E%3d&risl=&pid=ImgRaw&r=0",
            _id: undefined,
            lyricid: undefined,
            video: undefined,
            videodelayed:undefined,
            danmu:undefined,
            }
        }
    },
    computed: {
        filteredType(){
            let filteredMusic;
            if (this.listclassifier === "band"){
                filteredMusic = this.musicdata.filter(music => music.band === this.currentType);
            }
            else {
                filteredMusic = this.musicdata.filter(music => music.classifier === this.currentType);
            }
        // 对过滤后的音乐按 pinned 值排序，pinned 为 true 的放在前面
            return filteredMusic.sort((a, b) => {
                return !!b.pinned - !!a.pinned;
            });
        },
        musictype(){
            let musictypess;
                if (this.listclassifier === "band"){
                    musictypess = new Set(this.musicdata.map(item => item.band))
                }
                else {
                    musictypess = new Set(this.musicdata.map(item => item.classifier))
                }
            return Array.from(musictypess)
        }
    },
    created() {
        this.fetchdata()
    },
    mounted(){
        const video = this.$refs.VideoLoad;
        video.addEventListener('loadeddata', () => {
            console.log("Video is loaded and ready to play.");
        });
        video.addEventListener('ended', () => {
            console.log("Video ended, setting opacity to 0.");
            this.videoOpacity = 0;
        });
    },
    methods: {
        async fetchdata() {
            try {
                const musicdata = await axios.get("https://duanxianpi.com:3000/music")
                this.musicdata = musicdata.data
                
            }
            catch (err) {
                console.error('Error fetching data: ', err);
            }
        },
        changeMusic(index) {
            this.videoOpacity = 0;
            if (this.currentDisc.src){
                this.$refs.musicStart.pauseSong();
            }
            this.currentDisc = this.musicdata.find(music => music._id === index)
            this.$nextTick(() => {
            // 确保组件渲染完成并更新了currentDisc之后，再调用playSong方法
            if (this.$refs.musicStart) {
            this.$refs.musicStart.playSong();
            }
        });
        },
        changeList(key){
            this.currentType = key
        },
        async handlePinChanged(key){
            event.stopPropagation();
            try {
                await axios.get("https://duanxianpi.com:3000/musicpin?id=" + key).then((response)=>{
                const targetMusic = this.musicdata.find(music => music._id === key);
                targetMusic.pinned = response.data;
            }).catch((error)=>{console.log(error)})
            }
            catch(error){
                console.log(error)
            }
        },
        handleSongChanged(response){
            let currentIndex
            if (!this.currentDisc._id){
                currentIndex = -1;
            }
            else {
                currentIndex = this.filteredType.findIndex(music => music._id === this.currentDisc._id);
            }
            if (currentIndex < 0){
                this.changeMusic(this.filteredType[0]._id)
            }
            else {
                let newIndex; // 防止产生垃圾变量
                if (response){
                    newIndex = (currentIndex + 1) % this.filteredType.length;
                }
                else {
                    newIndex = (currentIndex - 1 + this.filteredType.length) % this.filteredType.length;
                }
                this.changeMusic(this.filteredType[newIndex]._id)
            }
        },
        changeMusicType(){
            if (this.listclassifier === "band") {
                // Switch to classifier and set currentType to Mandarin
                this.listclassifier = "classifier";
                this.currentType = "Mandarin";
            } else {
                // Switch to band and set currentType to 陶喆
                this.listclassifier = "band";
                this.currentType = "陶喆";
            }
        },
        VideoPlay(currentTime, changestate, isPlaying) {
            if (!this.currentDisc.video){
                this.videoOpacity = 0;
                console.log("没查到音乐");
                return;
            }
            const video = this.$refs.VideoLoad;
            if (video) {
                // Check if the video can be played

                    // If changestate is true, play or pause the video accordingly
                    if (changestate) {
                        if (video.paused) {
                            if (isPlaying){
                                video.currentTime = currentTime + Number(this.currentDisc.videodelayed);
                                this.videoOpacity = 0.7;
                                video.play();
                            }
                            else {
                                if (this.videoOpacity == 0.7){
                                    this.videoOpacity = 0;
                                }
                                else {
                                    video.currentTime = currentTime + Number(this.currentDisc.videodelayed);
                                    this.videoOpacity = 0.7;
                                }

                            }
                        } 
                        else {                   
                            this.videoOpacity = 0;
                            video.pause();
                        }
                    } 
                    else {
                        video.currentTime = currentTime + Number(this.currentDisc.videodelayed);
                        if (isPlaying){
                            video.play()
                        }
                        else {
                            video.pause()
                        }
                        // Update video time without changing play state
                    }
            }
        }
    },
    beforeUnmount() {
        const video = this.$refs.VideoLoad;

        if (video) {
            // Remove the event listeners
            video.removeEventListener('loadeddata', this.onVideoLoadedData);
            video.removeEventListener('ended', this.onVideoEnded);
        }
    }
}

</script>

<style scoped>
@font-face {
    font-family: 'icomoon';
    src:  url('fonts/fonts/icomoon.eot?3ufffe');
    src:  url('fonts/fonts/icomoon.eot?3ufffe#iefix') format('embedded-opentype'),
      url('fonts/fonts/icomoon.ttf?3ufffe') format('truetype'),
      url('fonts/fonts/icomoon.woff?3ufffe') format('woff'),
      url('fonts/fonts/icomoon.svg?3ufffe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
* {
    margin: 0;
    padding: 0;
    font-family: 'icomoon';
  }
.music-classifier {
    float: left;
    width: 15%;
    max-height: 50vh;
    overflow: auto;
    cursor: pointer;
}

.music-type {
    text-align: center;
    line-height: 5vh;
    min-height: 5vh;
    size: 2rem;
}

.music-introduction {
    float: left;
    width: 75%;
    min-height: 2vh;
    color: gray;
    border-bottom: 1px solid black;
}

.music-name {
    float: left;
    width: 39%;
    text-indent: 2rem;
}

.music-album-name {
    float: left;
    width: 26%;
    text-align: center;
}

.music-time {
    float: left;
    width: 35%;
    text-align: center;
}

.app {
  position: relative; /* Relative positioning to contain the video */
  z-index: 2; /* Higher z-index to overlay content above the video */
  float: left;
  width: 80%;
  max-height: 50vh; /* Limit the app container height */
  overflow-y: auto; /* Add vertical scroll */
}

.underlying-video {
  position: fixed; /* Absolute positioning relative to .app */
  top: 0;
  left: 0;
  z-index: 1; /* Lower z-index to place the video behind content */
  width: 100%;
  height: 100%; /* Match the height of the .app */ 
  object-fit: cover;
  pointer-events: none; /* Prevent interaction with the video */
}


.music-play-starter {
    position: relative;
    float: left;
    width: 100%;
    max-height: 20vh;
    z-index: 2;
}

@media (max-width: 520px) {
    /* * {
        background-color: black;
    } */
    .music-introduction {
        border-bottom: 1px solid rgb(218, 235, 176);
        background-color: black;
        color: rgb(250, 244, 207);
    }
    .music-album-name {
        width: 40%;
    }
    .music-time {
        width: 20%;
    }
    .music-classifier {
        display: none;
    }
    .music-introduction {
        width: 100%;
    }
    .app {
        width: 100%;
        max-height: 69vh;
    }
}
</style>
