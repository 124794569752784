import { createApp } from 'vue'
import MusicManager from './musicmanager.vue'

// 动态添加全局样式
const style = document.createElement('style')
style.textContent = `
  body {
    margin: 0;
    padding: 0;
    background-color: rgba(20,133,168,1);
  }
`
document.head.appendChild(style)

createApp(MusicManager).mount('#app')