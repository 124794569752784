<template>
    <div class="player-container">
        <div class="record-player">
            <div class="img-page">
                <div class="img-intro" :style="{'background-image':'url('+ cover + ')'}"></div>
            </div>
            <div class="lyric-page">
                <div class="lyric-title">
                    <h3>{{ disc }}</h3>
                    <h5>{{ band }}</h5>
                </div>
                <div class="lyric-content" >
                    <p>{{ lyricContent[0] }}</p>
                    <p class="lyric-green">{{ lyricContent[1] }}</p>
                    <p>{{ lyricContent[2] }}</p>
                    <p>{{ lyricContent[3] }}</p>
                </div>
            </div>
        </div>
        <div 
    class="danmuContent" 
    v-for="danmu in filteredDanmuData" 
    :key="danmu.id"
    :style="{'top':danmu.top}"
  >{{ danmu.text }}</div>
    </div>
</template>
<script>
export default {
    name: "MusicIntro",
    props: {
        lyricContent: {
            type: Array,
            required: true
        },
        disc: {
            type: String,
            required: true
        },
        band: {
            type: String,
            required: true
        },
        cover: {
            type: String,
            required: true
        },
        testTime: {
            required: true
        },
        danmuFile: {
            required: true
        },
        displayDanmu: {
            type: Boolean,
            requierd: true
        }

    },
    data() {

    },
    computed: {
        processedDanmuData() {
            return Object.entries(this.danmuFile).map(([key, danmu]) => {
                return {
                    start: danmu.start,
                    text: danmu.text,
                    // Assign a random top value between 20px and 220px
                    top: Math.floor(Math.random() * 200) + Math.floor(Math.random() * 100) + 20 + 'px',
                    // Use the key as the id (since key is the index)
                    id: key
                };
            });

        },
        filteredDanmuData() {
        const aligntime = -6;
        if (!this.displayDanmu){
            return []
        }
        return this.processedDanmuData.filter(danmu => {
            if (!danmu.start) {
                return false; // Skip this entry if start is undefined
            }
            const danmuTime = this.convertToSeconds(danmu.start);
            return danmuTime > this.testTime + aligntime && danmuTime < this.testTime + aligntime + 8 ;
        });
        }
    },
    methods: {
        convertToSeconds(timeStr) {
        // Convert "0:00:00.32" to seconds
        const [h, m, s] = timeStr.split(':');
        return parseInt(h) * 3600 + parseInt(m) * 60 + parseFloat(s);
        }
    }
}
</script>

<style scoped>

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes danmu-move {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

.player-container {
    position: relative;
    width: 100%;
    height: 50vh;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); */
}

.record-player {
    width: 100%;
    height: 100%;
    background-image: url('https://i.imgur.com/placeholder.jpg');
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out;
}

.record-player:hover {
    transform: scale(1.05);
}
.img-page {
    float: left;
    margin: 5%;
    height: 70%;
    width: 30%;
}

.img-intro {
    animation: rotate 5s linear infinite;
    margin: 2.5%;
    height: 30vh;
    width: 30vh;
    border-radius: 50%;
    background-image: url('https://media.gettyimages.com/id/71505391/photo/chyi-chin-golden-20-years-concert-in-nanjing.jpg?b=1&s=594x594&w=0&k=20&c=CXY6ptb7_JQS32e6nt_IgLjrqrrdd6e2P8fvHdIf-D8='); /* replace with your image path */
    background-size: cover; /* ensures the image covers the entire element */
    background-repeat: no-repeat;
}

.lyric-page {
    float: left;
    margin: 5%;
    height: 80%;
    width: 50%;
    color: white;
}
.lyric-title {
    margin: 0;
    width: 100%;
    height: 30%;
    text-align: center;
}
.lyric-content {
    margin: 0;
    width: 100%;
    height: 56%;
    overflow:hidden;
}
.lyric-content>p {
    text-align: center
}
.lyric-content>.lyric-green {
    font-size: 1.5em;
    color: green;
}
.danmuContent {
    position: absolute;
    top: 80px;
    z-index: 3;
    visibility: visible !important;
    color: white;
    white-space: nowrap;
    animation: danmu-move 8s linear infinite;
    color: #fff;
    font-size: 1.5rem;
    text-shadow: 0 0 3px black;
}

@media (max-width: 1000px) {
    .img-intro {
        width: 35vw;
        height: 35vw;
    }
    .danmuContent {
        font-size: 1.3rem;
    }
}
@media (max-width: 520px) {
    .img-intro {
        width: 35vw;
        height: 35vw;
    }
    .lyric-page {
        font-size: 1.05rem;
    }
    .lyric-content>.lyric-green {
        font-size: 1.25rem;
    }
    .danmuContent {
        font-size: 1rem;
    }
}
</style>