<template>
    <div :class="{'music-player':true,'music-player-animation':musicAnimation, 'music-player-bgc': videoPlaying? false: true }" >
      <MusicIntro class="reset-position" :key="danmuData.length + '-' + displayDanmu" :style="{'display':showup, 'background-color': showupcolor, 'visibility': showupvisible}" :disc="disc" :lyricContent="currentline" :band="band" :cover="cover" :testTime="currentTime" :danmuFile="danmuData" :displayDanmu="displayDanmu"></MusicIntro>
      <!-- 音乐信息 -->
      <div class="info" @click="pumpup">
        <img :src="cover" alt="封面" class="cover">
        <div class="details">
          <p class="title">{{ disc }}</p>
          <p class="artist">{{ band }}</p>
        </div>
      </div>
  
      <div class="controller">
        <div class="controls">
            <span class="controls-button">
                <div class="prev-button" @click="prevSong"></div>
                <div class="play-button" v-if="isPlaying" @click="pauseSong"></div>
                <div class="play-button" v-else @click="playSong"></div>
                <div class="next-button" @click="nextSong"></div>
                <audio ref="audioPlayer" :src="musicDir"></audio>
            </span>
        </div>
    
        <!-- 进度条 -->
        <div class="progress-bar">
            <input type="range" min="0" :max="duration" step="any" v-model.number="currentTime" @change="changePosition(currentTime)" @mousedown="startSeeking" @mouseup="finishSeeking">
        </div>
    
        <!-- 播放时间显示 -->
        <div class="time-display">
          <div class="time-show">
            <span>{{ formatTime(currentTime) }}</span> / 
            <span>{{ formatTime(duration) }}</span>
          </div>
          <div class="script-show">
            <span>{{ currentline[1] }}</span>
          </div>
        </div>


    </div>
  
      <!-- 其他功能按钮 -->
      <div class="functions">
        <button></button>
        <button @click="SpeedUp" :style="{'color':RateColor}"></button>
        <button @click="getDanmu">{{ danmuConfirm }}</button>
        <button @click="playVideo">{{ videoConfirm }}</button>
        <button></button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
import MusicIntro from "./musicintro.vue"

  export default {
    name: "MusicPlayBox",
    data() {
      return {
        isPlaying: false,
        currentTime: 0, // 当前播放时间（秒）
        duration: 0,   // 总时长（秒）
        lyric: {},
        danmuData: [],
        showup: "none",
        RateColor: "black",
        videoPlaying: false,
        isSeeking: false,
        loadDanmu: false,
        displayDanmu: false
      };
    },
    components: {
      MusicIntro
    },
    computed:{
      currentline() {
        let original = parseFloat(this.currentTime) * 1000
        let compare = 0
        let record = "无歌词可以加载"
        let recorder = 2
        let currentline = ["", record, "",""]
        for (let key in this.lyric) {
          if (key == "_id"){
            record = this.disc
            continue
          }
          compare = this.futimeToMilliseconds(key)
          if (recorder == 1) {
            currentline[3] = this.lyric[key];
            recorder -= 1
            continue
          }
          if (recorder == 0) {
            break
          }
          if (original <= compare) {
            // 当currentTime不大于当前key时，返回对应的歌词
            recorder -= 1
            currentline[2] = this.lyric[key]
          }
          else {
            currentline[0] = record
            currentline[1] = record = this.lyric[key]
          }


        }
        return currentline
      },
      videoConfirm(){
        return this.video? "" : "";
      },
      showupcolor(){
        return this.videoPlaying? undefined : "#1d1e22";
      },
      showupvisible(){
        return this.videoPlaying? "hidden" : "visible";
      },
      danmuConfirm(){
        return this.danmu?  "":"";
      },
      musicAnimation(){
        if(this.isPlaying && !this.videoPlaying){
          return true;
        }
        else {
          return false;
        }
      }
    },
    props: {
        musicDir: {
            type: String,
            requierd: true
        },
        disc: {
            type: String,
            required: true
        },
        band: {
            type: String,
            required: true
        },
        cover: {
            type: String,
            required: true
        },
        lyricid: {
          required: true
        },
        video: {
          required: true
        },
        videodelayed: {
          required: true
        },
        danmu: {
          requierd: true
        },
        _id: {
          required: true
        }
      },
    mounted() {
        this.$refs.audioPlayer.addEventListener('loadedmetadata', this.onLoadedMetadata);

        if (this.lyricid) {
          try {
            this.getlyric(this.lyricid)
          }
          catch(err){
            console.log(err)
          }
        }
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    beforeUnmount(){
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      this.$refs.audioPlayer.removeEventListener('loadedmetadata', this.onLoadedMetadata);
      this.$refs.audioPlayer.removeEventListener('timeupdate', this.updateCurrentTime);
      this.$refs.audioPlayer.removeEventListener('ended', this.nextSong);
    },
    methods: {
      updateCurrentTime() {
        if (!this.isSeeking){
          this.currentTime = this.$refs.audioPlayer.currentTime;
        }
      },
      onLoadedMetadata() {
        this.duration = Math.floor(this.$refs.audioPlayer.duration);
      },
      changePosition(nb) {
        this.$refs.audioPlayer.currentTime = Math.floor(nb);
        if(this.video){
          if (this.videoPlaying){
            this.$emit("VideoController",nb,false, this.isPlaying);
          }
        }
      },
      playSong() {
        this.isPlaying = true;
        // 这里应该调用实际的播放逻辑
        if(!this._id){
          this.nextSong();
          return;
        }
        this.$refs.audioPlayer.play()
        this.$refs.audioPlayer.addEventListener('timeupdate', this.updateCurrentTime);
        this.$refs.audioPlayer.addEventListener('ended', this.nextSong);
        if (this.video){
          if (this.videoPlaying){
            this.$emit("VideoController", this.currentTime,false, this.isPlaying);
          }
        }
      },
      pauseSong() {
        this.isPlaying = false;
        this.$refs.audioPlayer.pause()
        // 这里应该调用实际的暂停逻辑
        this.$refs.audioPlayer.removeEventListener('timeupdate', this.updateCurrentTime);
        this.$refs.audioPlayer.removeEventListener('ended', this.nextSong);
        if (this.video){
          if (this.videoPlaying){
            this.$emit("VideoController", this.currentTime,false, this.isPlaying);
          }
        }
      },
      prevSong() {
        this.$emit("changeSong",false);
      },
      nextSong() {
        this.$emit("changeSong",true);
      },
      formatTime(seconds) {
        const totalSeconds = Math.floor(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      },
      futimeToMilliseconds(timeStr) {
        const [minutes, seconds] = timeStr.split(':');
        return parseInt(minutes, 10) * 60000 + parseFloat(seconds) * 1000;
      },
      pumpup(){
        this.showup = this.showup === "block"? "none":"block";
      },
      async getlyric(lyricid){
        try {
          const lyricdata = await axios.get("https://duanxianpi.com:3000/lyric?targetid=" + lyricid)
          this.lyric = lyricdata.data
        }
        catch(err) {
          this.lyric = {}
        }
      },
      playVideo(){
        //必须检测有没有音乐
        if (this.video){
          this.videoPlaying = !this.videoPlaying;
          this.$emit("VideoController", this.currentTime,true, this.isPlaying);
        }
      },
      SpeedUp(){
        const audio = this.$refs.audioPlayer;
        if (audio.playbackRate <= 1) {
          this.RateColor = "yellow";
          audio.playbackRate += 0.5; // 每次点击增加0.5倍速
          } 
        else if (audio.playbackRate < 2){
          this.RateColor = "red";
          audio.playbackRate += 0.5;
        }
        else {
            this.RateColor = "black";
            audio.playbackRate = 1.0; // 如果大于2倍速，重置为1倍速
        }
      },
      handleVisibilityChange(){
            if (document.visibilityState === 'visible') {
                console.log("返回页面");
                if(this.isPlaying && this.videoPlaying){
                  if(this.video){
                    this.$emit("VideoController",this.currentTime,false, this.isPlaying);
                  }
                }
            }
      },
      startSeeking(){
        this.isSeeking = true
      },
      finishSeeking(){
        this.isSeeking = false
      },
      async getDanmu(){
        try {
          if (!this.loadDanmu && this.danmu){
            const danmuData = await axios.get("https://duanxianpi.com:3000/danmu?targetid=" + this.danmu);
            this.danmuData = danmuData.data;
            this.loadDanmu = true;
            this.displayDanmu = true;
            this.showup = "block";
          }
          else if (this.loadDanmu){
            if(!this.displayDanmu){
              this.showup = "block";
            }
            this.displayDanmu = !this.displayDanmu;

          }
          console.log(this.displayDanmu);
        }
        catch(error){
          console.log(error);
        }
      }


    }
  };
  </script>
  
  <style scoped>
    @font-face {
    font-family: 'icomoon';
    src:  url('fonts/fonts/icomoon.eot?3ufffe');
    src:  url('fonts/fonts/icomoon.eot?3ufffe#iefix') format('embedded-opentype'),
      url('fonts/fonts/icomoon.ttf?3ufffe') format('truetype'),
      url('fonts/fonts/icomoon.woff?3ufffe') format('woff'),
      url('fonts/fonts/icomoon.svg?3ufffe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  * {
      font-family: 'icomoon';
  }
  .music-player {
    position: relative;
    display: flex;
    align-items: center;
  }
  .reset-position {
    position: absolute; /* Absolutely position the MusicIntro component */
    top: -50vh; /* Adjust this value to position it above the music player box */
    /* Add any other styles you need for the MusicIntro component */
  }
  
  .info {
    display: flex;
    min-width: 15%;
    align-items: center;
  }
  
  .cover {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  
  .details {
    text-align: left;
  }
  
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .artist {
    font-size: 14px;
    color: #666;
  }
  .controller {
    width: 50%;
  }
  .controls {
    width: 100%;
    text-align: center;
  }

  .controls-button {
    display: inline-block;
  }

  .play-button {
    background-color: green;
    border-radius: 50%;
  }

  .controls div {
    cursor: pointer;
    float: left;
    margin: 0 10px;
  }

  
  .progress-bar input[type=range] {
    width: 100%;
  }
  
  .time-display {
    margin-left: auto;
    margin-right: 30px;
  }
  .time-show {
    display: inline-block;
    width: 20%;
    text-align: left;
  }
  .script-show {
    display: inline-block;
    width: 60%;
    text-align: center;
  }
  .functions {
    display: flex;               /* Enable flexbox layout */
    justify-content: center;      /* Center the buttons horizontally */
    align-items: center;          /* Center the buttons vertically (if needed) */
  }
  .functions button {
    margin-left: 15px;
  }
  .music-player-bgc {

  }
  .music-player-animation {

  }
  @media (max-width: 520px){
    @keyframes gradientTransition {
      0% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 98%);
      }
      7% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 95%);
      }
      14% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 92%);
      }
      21% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 89%);
      }
      28% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 86%);
      }
      34% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 83%);
      }
      40% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 80%);
      }
      47% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 77%);
      }
      54% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 74%);
      }
      60% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 71%);
      }
      67% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 68%);
      }
      74% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 65%);
      }
      81% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 62%);
      }
      87% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 59%);
      }
      94% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 57%);
      }
      100% {
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 56%);
      }
    }

    .music-player {
      border-radius: 3px;
      min-height: 22vh;
      color: white;
    }
    .music-player-bgc {
      background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(20,133,168,1) 98%);
    }
    .music-player-animation {
      animation: gradientTransition 3s infinite alternate ease-in-out;
    }
    .info {
      margin-left: 25%;
    }
    .controller {
      width: 96%;
      margin-left: 2%;
    }
    .time-display {
      min-height: 5vh;
    }
    .music-player {
      display: block;
    }
    .time-show {
      width: 30%;
      font-size: 0.9rem;
    }
    .functions {
      height: 10%;
    }
    .script-show>span {
      font-size: 0.7rem;
    }
  }

  
  </style>