<template>
    <div class="music-player">
        <!-- 音乐封面 -->
        <img :src="cover" alt="Album Cover" class="album-cover">

        <!-- 歌曲信息 -->
        <div class="song-info">
            <h3>{{ disc }}</h3>
            <span>{{ band }}</span>
        </div>

        <!-- 播放控制按钮 -->
        <div :class="{'play-controller': true, 'play-controller-pinned': pinned}" @click="pinMusic" ></div>
        <div class="album-info">{{ album }}</div>
        <div class="time-line">5:55</div>
        
    </div>
</template>

<script>
export default {
    name: "MusicList",
    methods: {
        pinMusic(){
            this.$emit("changepin");
        }
    },
    props: {
        musicDir: {
            type: String,
            requierd: true
        },
        disc: {
            type: String,
            required: true
        },
        band: {
            type: String,
            required: true
        },
        album: {
            type: String,
            required: true
        },
        cover: {
            type: String,
            required: true
        },
        pinned: {
            type: Boolean,
            requierd: true
        }

    }
}
</script>

<style scoped>
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/fonts/icomoon.eot?3ufffe');
  src:  url('fonts/fonts/icomoon.eot?3ufffe#iefix') format('embedded-opentype'),
    url('fonts/fonts/icomoon.ttf?3ufffe') format('truetype'),
    url('fonts/fonts/icomoon.woff?3ufffe') format('woff'),
    url('fonts/fonts/icomoon.svg?3ufffe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
* {
    font-family: 'icomoon';
}
.music-player {
    display: flex;
    align-items: center;
    position: relative;
    /* background-image: linear-gradient(45deg, rgb(0, 102, 255), green); */
}

.music-player:hover {
    transition: 0.1s;
    /* background-image: linear-gradient(225deg, rgb(0, 102, 255), green); */
    background-color: rgb(189, 177, 177);
    z-index: 5 !important;
    opacity: 1 !important;
}

.album-cover {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    margin-left: 5px;
}
.song-info {
    width: 20%;
}

.song-info h2, .song-info span {
    margin-bottom: 5px;
}
.play-controller {
    margin-left: 10%;
    font-size: 1.5em;
    text-align: center;
    color: rgb(24, 91, 155);
}
.play-controller-pinned {
    color: red;
}
.album-info {
    margin-left: 10%;
    width: 20%;
    line-height: 100%;
    font-size: 1em;
    color: gray;
}
.time-line {
    width: 20%;
    text-align: center;
}
@media (max-width: 520px) {
    .music-player {
        background-color: black;
    }
    .album-info {
        font-size: 0.9rem;
    }
    .song-info {
        width: 31%;
    }
    h3 {
        font-size: 1rem;
    }
    .song-info span {
        font-size: 0.8rem;
    }
    .play-controller {
        margin-left: 6%;
        font-size: 1rem;
    }
    .time-line {
        font-size: 0.8rem;
    }
    .music-player:hover {
        /* background-image: linear-gradient(225deg, rgb(0, 102, 255), green); */
        background-color: rgb(22, 21, 23);
    }
}
</style>